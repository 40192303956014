import React from 'react';

const Terms = () => {
     
 return <>
            <div style={{ backgroundColor:"#fff"}}>
                   <div style={{ padding: "50px"}}>
                      <h3>OPŠTI USLOVI KUPOVINE</h3>
                     <p>Kupovinom na našem sajtu <b>www.vestige.ba</b> Vi se obavezujete da ste pročitali sve na našem sajtu.
                     Ukoliko imate problema i nejasnoća, možete nas slobodno pozvati a nama će biti drago da Vam pomognemo.  </p>
                  
                  
                   </div>
              </div>
        </>
}

export default Terms;