import React from 'react';

const BackDirection = (props) => {

    return <svg style={props.style} version="1.1" id="Capa_1"  x="0px" y="0px"
    viewBox="0 0 341.333 341.333" >
<g>
   <g>
       <polygon points="341.333,149.333 81.707,149.333 200.853,30.187 170.667,0 0,170.667 170.667,341.333 200.853,311.147 81.707,192 
           341.333,192 		"/>
   </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

}

export default BackDirection;