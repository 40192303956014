export const articalData = () => {
    return {
        UIname: 'Atomizer za parfeme',
        images: ['atomizer-1', 'atomizer-2', 'atomizer-3'],
        controlls: [
            {
                price: {
                    normalPrice: 15.1,
                    discount: null
                },
                option: 'crveni'
            },

            {
                price: {
                    normalPrice: 10.2,
                    discount: null
                },
                option: 'crni'
            }
        ]
    }
}